import {
  IFacilitySummaryInfo,
  IGreenHouseGasesAvoided,
  IPvForeCastData,
  IFacilityMonitoring,
} from "@/models/Site";
import { ErrorResponse } from "@/services/axios/error";
import { SiteAPI } from "@/services/site";
import store from "@/store";
import isEmpty from "lodash/isEmpty";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

const name = "FacilitiesMonitoringModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class FacilitiesMonitoringModule extends VuexModule {
  private error = {} as ErrorResponse;

  private dataPvForest = {} as IPvForeCastData;
  private errorPvForest = {} as ErrorResponse;
  private loadingPvForest = false;

  private dataFacilitySummaryInfo = {} as IFacilitySummaryInfo;
  private loadingFacilitySummaryInfo = false;
  private errorFacilitySummaryInfo = {} as ErrorResponse;

  private loadingGreenHouseGasesAvoided = false;
  private dataGreenHouseGasesAvoided = {} as IGreenHouseGasesAvoided;
  private errorGreenHouseGasesAvoided = {} as ErrorResponse;

  private facilityMonitoringData = [] as IFacilityMonitoring[];
  private facilityMonitoringOnlineData = [] as IFacilityMonitoring[];
  private loadingfacilityMonitoringData = false;
  private errorfacilityMonitoringData = {} as ErrorResponse;

  get isError() {
    return !isEmpty(this.error);
  }

  get dataPvForestGetter() {
    return this.dataPvForest;
  }

  get LoadingPvForest() {
    return this.loadingPvForest;
  }

  get errorPvForestGetter() {
    return this.errorPvForest;
  }

  get facilityMonitoringDataGetter() {
    return this.facilityMonitoringData;
  }
  get facilityMonitoringOnlineDataGetter() {
    return this.facilityMonitoringOnlineData;
  }

  get loadingfacilityMonitoringDataGetter() {
    return this.loadingfacilityMonitoringData;
  }

  @Mutation
  setLoadingPvForest(isLoading = false as boolean) {
    this.loadingPvForest = isLoading;
  }

  @Mutation
  getPvForestError(error: ErrorResponse) {
    this.loadingPvForest = false;
    this.errorPvForest = error;
  }

  @Mutation
  getPvForestSuccess(data: IPvForeCastData) {
    this.loadingPvForest = false;
    this.dataPvForest = data;
  }

  @Action
  async getDataPvForestAction(FacilityId: string) {
    this.setLoadingPvForest(true);
    const servicesSiteAPI = new SiteAPI();
    const { error, data } = await servicesSiteAPI.getSitesPvForecast(
      FacilityId
    );
    if (error) {
      this.getPvForestError(error);
    } else {
      this.getPvForestSuccess(data);
    }
  }

  // Green House Gases
  get dataGreenHouseGasesGetter() {
    return this.dataGreenHouseGasesAvoided;
  }

  get loadingGreenHouseGasesGetter() {
    return this.loadingGreenHouseGasesAvoided;
  }

  get errorGreenHouseGasesGetter() {
    return this.errorGreenHouseGasesAvoided;
  }
  @Mutation
  setGreenHouseGasesAvoided(isLoading = false as boolean) {
    this.loadingGreenHouseGasesAvoided = isLoading;
  }

  @Mutation
  setGreenHouseGasesAvoidedError(error: ErrorResponse) {
    this.loadingGreenHouseGasesAvoided = false;
    this.errorGreenHouseGasesAvoided = error;
  }

  @Mutation
  getGreenHouseGasesAvoidedSuccess(data: IGreenHouseGasesAvoided) {
    this.loadingGreenHouseGasesAvoided = false;
    this.dataGreenHouseGasesAvoided = data;
  }

  @Action
  async getDataGreenHouseGasesAvoided(FacilityId: string) {
    const servicesSiteAPI = new SiteAPI();
    const { error, data } =
      await servicesSiteAPI.getSitesGreenHouseGasesAvoided(FacilityId);
    if (error) {
      this.setGreenHouseGasesAvoidedError(error);
    } else {
      this.getGreenHouseGasesAvoidedSuccess(data);
    }
  }
  // Facility Summary Information

  get dataFacilitySummaryInfoGetter() {
    return this.dataFacilitySummaryInfo;
  }

  get loadingFacilitySummaryInfoGetter() {
    return this.loadingFacilitySummaryInfo;
  }

  get errorFacilitySummaryInfoGetter() {
    return this.errorFacilitySummaryInfo;
  }

  @Mutation
  setLoadingFacilitySummaryInfo(isLoading = false as boolean) {
    this.loadingFacilitySummaryInfo = isLoading;
  }

  @Mutation
  setFacilitySummaryInfoError(error: ErrorResponse) {
    this.loadingFacilitySummaryInfo = false;
    this.errorFacilitySummaryInfo = error;
  }

  @Mutation
  setFacilitySummaryInfoSuccess(data: IFacilitySummaryInfo) {
    this.loadingFacilitySummaryInfo = false;
    this.dataFacilitySummaryInfo = data;
  }

  @Action
  async fetchDataFacilitySummaryInfo(FacilityId: string) {
    this.setLoadingFacilitySummaryInfo(true);
    const servicesSiteAPI = new SiteAPI();
    const { error, data } = await servicesSiteAPI.getSitesFacilitySummaryInfo(
      FacilityId
    );
    if (error) {
      this.setFacilitySummaryInfoError(error);
    } else {
      this.setFacilitySummaryInfoSuccess(data);
    }
  }

  @Mutation
  setLoadingFacilityMonitoring(isLoading = false as boolean) {
    this.loadingfacilityMonitoringData = isLoading;
  }
  @Mutation
  getFacilityMonitoringError(error: ErrorResponse) {
    this.loadingfacilityMonitoringData = false;
    this.errorfacilityMonitoringData = error;
  }

  @Mutation
  getFacilityMonitoringSuccess(data: IFacilityMonitoring[]) {
    this.loadingfacilityMonitoringData = false;
    this.facilityMonitoringData = data;
    this.facilityMonitoringOnlineData = data.filter(
      (item: IFacilityMonitoring) => item?.IsOnline
    );
  }

  @Action
  async getFacilityMonitoringAction() {
    this.setLoadingFacilityMonitoring(true);
    const servicesSiteAPI = new SiteAPI();
    const { error, data } = await servicesSiteAPI.getFacilityMonitoring();
    if (error) {
      this.getFacilityMonitoringError(error);
    } else {
      this.getFacilityMonitoringSuccess(data);
    }
  }
}
export default getModule(FacilitiesMonitoringModule);
